import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, useServices } from 'cng-web-lib'
import { Grid, Divider, Typography } from '@material-ui/core'
import { ACIHighwayTranslationContext } from '../../contexts/ACIHighwayTranslationContext'
import { NaCustomsOfficeAutocompleteField, NaSublocationWithoutCodeAutocompleteField,NaUSPortAutocompleteField } from 'src/components/na'
import { NaStateCodeAutoCompleteField } from 'src/components/na'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import Checkbox from 'src/components/Checkbox'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import { useLocation } from 'react-router-dom'
import TemplateSelectDialogWithOptionACIHWYCargo from 'src/components/aciacehighway/TemplateSelectDialogWithOptionACIHWYCargo'

const {
    button: { CngButton },
    form: {
        field: {
            CngCheckboxField,
            CngTextField,
            CngDateField,
            CngTimeField,
            CngCodeMasterAutocompleteField,
            CngCountryAutocompleteField,
            CngSelectField
        },
    },
    CngGridItem,
} = components

function CargoDetailsSection(props) {
    const { disabled, shouldHideMap, user, cargoTypes, showNotification, manifestCarrierCode } = props
    const { getValues, setValue, reset, trigger } = useFormContext();
    const { securedSendRequest } = useServices()
    const { getTranslatedText } = useContext(ACIHighwayTranslationContext)
    const location = useLocation();

    const manifestId = useWatch({ name: 'manifestId' });
    const cargoType = useWatch({ name: "cargoType" });
    const cargoParties = useWatch({ name: 'cargoParties' })
    const carrierCode = useWatch({ name: 'carrierCode' });
    const id = useWatch({ name: 'id' })
    const ccn = useWatch({ name: 'ccn' });
    const firstExpectedPort = useWatch({ name: 'firstExpectedPort' });
    const warehouseCode = useWatch({ name: 'warehouseCode' });
    const paperRelease = useWatch({ name: 'paperRelease' })
    const poaValues = useWatch({ name: ['poaCity', 'poaCountryCode', 'poaStateCode'] })
    const adminValues = useWatch({ name: ['adminName', 'adminContact', 'specialInstructions'] })
    const printValues = useWatch({ name: ['portOfExit', 'acquittalNo', 'printDate'] })
    const templateFlag = useWatch({ name: 'templateFlag' });
    const polCountryCode = useWatch({ name: 'polCountryCode' });
    const polStateCode = useWatch({ name: 'polStateCode' })
    const poaCountryCode = useWatch({ name: 'poaCountryCode' });
    const poaStateCode = useWatch({ name: 'poaStateCode' });
    const portOfExit = useWatch({ name: 'portOfExit' });
    const tripNo = useWatch({ name: 'tripNo' });
    const entryNo = useWatch({ name: 'entryNo' });
    const arrivalDateAndTimeZone = useWatch({ name: 'arrivalDateAndTimeZone' });
    const [onChangeCarrierCode, setOnChangeCarrierCode] = useState(false)
    const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false

    const [cargoLoadedInList, setCargoLoadedInList] = useState([]);
    const [cargoTemplateDialog, setCargoTemplateDialog] = useState(false)
    const [refreshCarrierCodeAndCCN, setRefreshCarrierCodeAndCCN] = useState(false)
    const isEditTemplate = getValues('isEditTemplate')

    const city_const = 'CITY'
    const country_const = 'COUNTRY'
    const state_const = 'STATE'

    useEffect(() => {
        function onSuccess(response) {
            setCargoLoadedInList(response.data)
        }

        function onError(error) {
            console.log(error);
        }

        function onComplete() { }
        const config = {}

        securedSendRequest.execute('POST', AciHighwayCargoApiUrls.GET_CARGO_LOADED, { manifestId }, onSuccess, onError, onComplete, config, null);
    }, []);

    useEffect(() => {
        if (manifestCarrierCode && ccn === '') {
            setValue("carrierCode", manifestCarrierCode);
            setValue("ccn", manifestCarrierCode);
        }
    }, [])

    const [scacList, setScacList] = useState([]);
    useEffect(() => {
        var scacCode = '';
        var module = '';
        var jsonArray = [];
        if (user && user.scacs) {
            Object.entries(user.scacs).forEach((item) => {
                for (const key in item[1]) {
                    if (key == 'scacCode') {
                        scacCode = item[1][key];
                    }
                    if (key == 'module') {
                        module = item[1][key];
                    }
                }

                if (module == 'ACIHW') {
                    var jsonObject = { text: scacCode, value: scacCode };
                    jsonArray.push(jsonObject);
                }
            })
            setScacList(jsonArray);

            if (jsonArray.length > 0) {
                const isCarrierCodeFound = jsonArray && jsonArray.some(element => {
                    if (element.value === carrierCode) {
                        return true;
                    }
                    return false;
                });

                if (editPage) {
                    if (!isCarrierCodeFound) {
                        setValue('carrierCode', '');
                    } else {
                        setOnChangeCarrierCode(true);
                    }
                } else {
                    if (isCarrierCodeFound) {
                        setValue('carrierCode', getValues('carrierCode'));
                        setValue('ccn', getValues('carrierCode'));
                        setOnChangeCarrierCode(true);
                    } else {
                        setValue('carrierCode', '');
                        setValue('ccn', '');
                    }
                }
                setRefreshCarrierCodeAndCCN(false);
            }

            let flag = user.userAutoScns[0];
            if (!id && manifestCarrierCode && flag && flag.autoScn === 'true' && flag.module.includes("ACIHW")) {
                setValue("carrierCode", manifestCarrierCode);
                setValue("ccn", manifestCarrierCode + getCurrentDateTime());
            }

            if (!manifestCarrierCode && (jsonArray && jsonArray.length === 1)) {
                setValue("carrierCode", jsonArray[0].value);
                if (flag && flag.autoScn === 'true' && flag.module.includes("ACIHW")) {
                    setValue("ccn", jsonArray[0].value + getCurrentDateTime());
                } else {
                    if (ccn === '') {
                        setValue("ccn", jsonArray[0].value);
                    }
                }
            }
        }
    }, [user, refreshCarrierCodeAndCCN]);

    const [ccnAutoGenerated, setCcnAutoGenerated] = useState(false);
    useEffect(() => {
        if (user && user.userAutoScns) {
            let flag = user.userAutoScns[0];
            setCcnAutoGenerated(flag && flag.autoScn === 'true' && flag.module.includes('ACIHWY'));
        }
    }, [user])

    function getCurrentDateTime() {
        let date = new Date();
        return date.toISOString().split('.')[0].replace(/[^\d]/gi, '')
    }

    function applyPolToShipper(field, value) {
        if (!cargoParties) {
            return
        }

        let tempParties = cargoParties

        tempParties.forEach(e => {
            if (e.partyType == 'SH') {
                if (field == city_const) {
                    e.city = value
                } else if (field == country_const) {
                    e.countryCode = value
                } else if (field == state_const) {
                    e.stateCode = value
                }
            }
        })

        setValue('cargoParties', tempParties)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap.templateFlag}>
                        <CngCheckboxField
                            label={
                                <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                                    {getTranslatedText('ACI_HIGHWAY_CARGO', 'templateFlag')}
                                </Typography>
                            }
                            name='templateFlag'
                            disabled={disabled || isEditTemplate}
                            onChange={(e) => setValue('templateFlag', e.target.checked)}
                            size='small'
                            style={{ marginLeft: 4, marginRight: 4, padding: 8 }}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} lg shouldHide={shouldHideMap.templateName}>
                        <CngTextField
                            required
                            name='templateName'
                            inputProps={{ maxLength: 50 }}
                            placeholder={getTranslatedText('ACI_HIGHWAY_CARGO', 'templateName')}
                            disabled={disabled || templateFlag === false || isEditTemplate}
                            onChange={(e) => { setValue('templateName', e.target.value.toUpperCase()) }}
                            onBlur={(e) => {
                                if (e.target.value !== undefined) {
                                    setValue('templateName', e.target.value.toUpperCase().trim())
                                }
                            }}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} lg='auto'>
                        <CngButton size='medium' color='secondary' onClick={() => setCargoTemplateDialog(true)}
                            startIcon={<FontAwesomeIcon icon={['fal', 'money-check-edit']} />}>
                            Use template
                        </CngButton>
                    </CngGridItem>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.cargoType}>
                        <CngSelectField
                            required
                            name='cargoType'
                            label={getTranslatedText('ACI_HIGHWAY_CARGO', 'cargoType')}
                            disabled={disabled}
                            items={cargoTypes}
                            onChange={(value) => {
                                if (value.target.value !== 'IN-BOND') {
                                    setValue('inbondDestinationOfficeCode', '')
                                    setValue('warehouseCode', '')
                                }
                                if (value.target.value === 'OSC') {
                                    setValue('cargoParties', [])
                                 }
                            }}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.consolidated}>
                        <CngCodeMasterAutocompleteField
                            required
                            name='consolidated'
                            label={getTranslatedText('ACI_HIGHWAY_CARGO', 'consolidated')}
                            disabled={disabled || getValues('status') === '1000'}
                            codeType='ACIHW_CARGO_CONSOLIDATED'
                            size='small'
                        />
                    </CngGridItem>
                    {(cargoType !== 'OSC') && (
                        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.paperRelease}>
                            <Checkbox
                                checked={paperRelease === 'Y' ? true : false}
                                name='paperRelease'
                                label={getTranslatedText('ACI_HIGHWAY_CARGO', 'paperRelease')}
                                disabled={disabled}
                                fullWidth
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        setValue('paperRelease', 'Y')
                                    } else {
                                        setValue('paperRelease', 'N')
                                    }
                                }}
                                size='small'
                            />
                        </CngGridItem>
                    )}
                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.carrierCode}>
                        <CngSelectField
                            required={!isEditTemplate}
                            name='carrierCode'
                            label={getTranslatedText('ACI_HIGHWAY_CARGO', 'carrierCode')}
                            disabled={disabled || getValues('status') === '1000'}
                            items={scacList}
                            // onChange={(e) => {
                            //     if (ccnAutoGenerated) {
                            //         setValue('ccn', e.target.value.toUpperCase() + getCurrentDateTime())
                            //     } else if (ccn === '' || ccn.length <= 4) {
                            //         setValue('ccn', e.target.value.toUpperCase())
                            //     }
                            // }}
                            onChange={(e) => {
                                if (ccnAutoGenerated) {
                                    setValue("ccn", e.target.value.toUpperCase() + getCurrentDateTime(), { shouldValidate: true })
                                } else if (ccn === '') {
                                    setOnChangeCarrierCode(true);
                                    setValue("ccn", e.target.value.toUpperCase(), { shouldValidate: true })
                                } else {
                                    if (onChangeCarrierCode) {
                                        var removedPrefix = ccn.substring(4);
                                        setValue("ccn", e.target.value.toUpperCase() + removedPrefix, { shouldValidate: true })
                                    } else {
                                        setOnChangeCarrierCode(true);
                                        setValue("ccn", e.target.value.toUpperCase() + ccn, { shouldValidate: true })
                                    }
                                }
                            }}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.ccn}>
                        <CngTextField
                            required={!isEditTemplate}
                            name='ccn'
                            label={getTranslatedText('ACI_HIGHWAY_CARGO', 'ccn')}
                            disabled={ccnAutoGenerated || getValues('status') === '1000' || isEditTemplate}
                            inputProps={{ maxLength: 25 }}
                            // onChange={(e) => {
                            //     setValue('ccn', e.target.value.toUpperCase())
                            // }}
                            onChange={(e) => {
                                setValue("ccn", e.target.value.toUpperCase(), { shouldValidate: true })
                            }}
                            onBlur={(e) => {
                                if (e.target.value !== undefined) {
                                    setValue("ccn", e.target.value.trim(), { shouldValidate: true })
                                }
                            }}
                            size='small'
                        />
                    </CngGridItem>
                    {cargoType !== 'OSC' && (
                        <>
                            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.firstExpectedPort}>
                                <NaCustomsOfficeAutocompleteField
                                    required
                                    name='firstExpectedPort'
                                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'firstExpectedPort')}
                                    disabled={disabled}
                                    lookupProps={{
                                        filters: [
                                            {
                                                field: 'indicator',
                                                operator: 'equal',
                                                value: 'ACI'
                                            }
                                        ]
                                    }}
                                    size='small'
                                    key={firstExpectedPort}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.arrivalDate}>
                                <CngDateField
                                    required
                                    name='arrivalDate'
                                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'arrivalDate')}
                                    format='YYYY-MM-DD'
                                    disabled={disabled /*|| getValues('status') === '1000'*/}
                                    size='small'
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.arrivalTime}>
                                <CngTimeField
                                    required
                                    name='arrivalTime'
                                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'arrivalTime')}
                                    format='HH:mm'
                                    disabled={disabled}
                                    size='small'
                                    ampm={false}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.arrivalDateAndTimeZone}>
                                <CngCodeMasterAutocompleteField
                                    name='arrivalDateAndTimeZone'
                                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'arrivalDateAndTimeZone')}
                                    disabled={disabled}
                                    codeType='SCHEDULER_TIME_ZONE'
                                    size='small'
                                    key={arrivalDateAndTimeZone}
                                />
                            </CngGridItem>
                        </>
                    )}
                    {cargoType !== 'OSC' && cargoType === 'IN-BOND' && (
                        <>
                            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.inbondDestinationOfficeCode}>
                                <NaCustomsOfficeAutocompleteField
                                    required
                                    name='inbondDestinationOfficeCode'
                                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'inbondDestinationOfficeCode')}
                                    disabled={disabled}
                                    lookupProps={{
                                        filters: [
                                            {
                                                field: 'indicator',
                                                operator: 'equal',
                                                value: 'ACI'
                                            }
                                        ]
                                    }}
                                    key={warehouseCode}
                                    size='small'
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.warehouseCode}>
                                <NaSublocationWithoutCodeAutocompleteField
                                    required
                                    name='warehouseCode'
                                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'warehouseCode')}
                                    disabled={disabled}
                                    lookupProps={{
                                        filters: [
                                            {
                                                field: 'indicator',
                                                operator: 'equal',
                                                value: 'ACI'
                                            }
                                        ]
                                    }}
                                    onChange={(e, f) => {
                                        setValue('inbondDestinationOfficeCode', f.data.code, {
                                            shouldValidate: true
                                        })
                                    }}
                                    size='small'
                                />
                            </CngGridItem>
                        </>
                    )}
                    {(cargoType !== 'OSC') && (
                        <CngGridItem xs={12} shouldHide={shouldHideMap.printRemarks}>
                            <CngTextField
                                name='printRemarks'
                                label={getTranslatedText('ACI_HIGHWAY_CARGO', 'printRemarks')}
                                disabled={disabled}
                                inputProps={{ maxLength: 200 }}
                                multiline
                                row={2}
                                onChange={(e) => { setValue('printRemarks', e.target.value.toUpperCase().replace(/(\r\n|\r|\n)+/g, ' ')) }}
                                size='small'
                            />
                        </CngGridItem>
                    )}

                    {(tripNo !== '') && (
                        <>
                            <CngGridItem xs={4} sm={4} lg={4} shouldHide={shouldHideMap.cargoLoadedIn}>
                                <CngSelectField
                                    name='cargoLoadedIn'
                                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'cargoLoadedIn')}
                                    disabled={disabled}
                                    items={cargoLoadedInList}
                                    size='small'
                                />
                            </CngGridItem>
                            {cargoType !== 'CSA' &&
                                <CngGridItem xs={4} sm={4} lg={4} shouldHide={shouldHideMap.entryNo}>
                                    <CngTextField
                                        name='entryNo'
                                        label={entryNo === '' ? 'Not Available' : getTranslatedText('ACI_HIGHWAY_CARGO', 'entryNo')}
                                        disabled={true}
                                        size='small'
                                    />
                                </CngGridItem>
                            }
                        </>
                    )}

                </Grid>
            </Grid>

            {(cargoType !== 'OSC') && (
                <>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid alignItems='center' container spacing={2}>
                                    <CngGridItem xs={12} sm='auto'>
                                        <Typography variant='body2'>
                                            {getTranslatedText('ACI_HIGHWAY_CARGO', 'placeOfLoadingTitle')}
                                        </Typography>
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm><Divider /></CngGridItem>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.polCity}>
                                        <CngTextField
                                            required
                                            name='polCity'
                                            inputProps={{ maxLength: 30 }}
                                            label={getTranslatedText('ACI_HIGHWAY_CARGO', 'polCity')}
                                            disabled={disabled}
                                            onBlur={(e) => {
                                                setValue('polCity', e.target.value.toUpperCase())
                                                applyPolToShipper(city_const, e.target.value.toUpperCase())
                                            }}
                                            size='small'
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.polCountryCode}>
                                        <CngCountryAutocompleteField
                                            required
                                            name='polCountryCode'
                                            label={getTranslatedText('ACI_HIGHWAY_CARGO', 'polCountryCode')}
                                            disabled={disabled}
                                            onChange={() => {
                                                setValue('polStateCode', '')
                                                setValue('polCountryCode', getValues('polCountryCode'))
                                                applyPolToShipper(country_const, getValues('polCountryCode'))
                                            }}
                                            key={polCountryCode}
                                            lookupProps={{
                                                label: (record) => {
                                                    return `${record.code};${record.descriptionEn}`
                                                }
                                            }}
                                            size='small'
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.polStateCode}>
                                        <NaStateCodeAutoCompleteField
                                            required={polCountryCode === 'US'}
                                            name='polStateCode'
                                            label={getTranslatedText('ACI_HIGHWAY_CARGO', 'polStateCode')}
                                            disabled={disabled}
                                            countryCode={polCountryCode}
                                            key={polCountryCode + polStateCode}
                                            size='small' ry
                                            onChange={() => {
                                                setValue('polStateCode', getValues('polStateCode'))
                                                applyPolToShipper(state_const, getValues('polStateCode'))
                                            }}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <CollapsibleSection
                            defaultExpanded={poaValues.filter((value) => value).length > 0}
                            title='Place of Acceptance (Pick Up Location)'
                        >
                            <Grid container spacing={1}>
                                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.poaCity}>
                                    <CngTextField
                                        name='poaCity'
                                        label={getTranslatedText('ACI_HIGHWAY_CARGO', 'poaCity')}
                                        disabled={disabled}
                                        inputProps={{ maxLength: 30 }}
                                        onChange={(e) => {
                                            setValue('poaCity', e.target.value.toUpperCase())
                                        }}
                                        size='small'
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.poaCountryCode}>
                                    <CngCountryAutocompleteField
                                        name='poaCountryCode'
                                        label={getTranslatedText('ACI_HIGHWAY_CARGO', 'poaCountryCode')}
                                        disabled={disabled}
                                        onChange={() => {
                                            setValue('poaStateCode', '')
                                        }}
                                        key={poaCountryCode}
                                        lookupProps={{
                                            label: (record) => {
                                                return `${record.code};${record.descriptionEn}`
                                            }
                                        }}
                                        size='small'
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.poaStateCode}>
                                    <NaStateCodeAutoCompleteField
                                        name='poaStateCode'
                                        label={getTranslatedText('ACI_HIGHWAY_CARGO', 'poaStateCode')}
                                        disabled={disabled}
                                        countryCode={poaCountryCode}
                                        key={poaCountryCode + poaStateCode}
                                        size='small'
                                    />
                                </CngGridItem>
                            </Grid>
                        </CollapsibleSection>
                    </Grid>
                    <Grid item xs={12}>
                        <CollapsibleSection
                            defaultExpanded={adminValues.filter((value) => value).length > 0}
                            title={getTranslatedText('ACI_HIGHWAY_CARGO', 'adminstrativeContact')}
                        >
                            <Grid container spacing={1}>
                                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.adminName}>
                                    <CngTextField
                                        name='adminName'
                                        label={getTranslatedText('ACI_HIGHWAY_CARGO', 'adminName')}
                                        disabled={disabled}
                                        inputProps={{ maxLength: 60 }}
                                        onChange={(e) => {
                                            setValue('adminName', e.target.value.toUpperCase())
                                        }}
                                        size='small'
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={6} md={8} shouldHide={shouldHideMap.adminContact}>
                                    <CngTextField
                                        name='adminContact'
                                        label={getTranslatedText('ACI_HIGHWAY_CARGO', 'adminContact')}
                                        disabled={disabled}
                                        inputProps={{ maxLength: 35 }}
                                        onChange={(e) => {
                                            setValue('adminContact', e.target.value.toUpperCase())
                                        }}
                                        size='small'
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} shouldHide={shouldHideMap.specialInstructions}>
                                    <CngTextField
                                        name='specialInstructions'
                                        label={getTranslatedText('ACI_HIGHWAY_CARGO', 'specialInstructions')}
                                        disabled={disabled}
                                        inputProps={{ maxLength: 300 }}
                                        onChange={(e) => {
                                            setValue('specialInstructions', e.target.value.toUpperCase())
                                        }}
                                        size='small'
                                    />
                                </CngGridItem>
                            </Grid>
                        </CollapsibleSection>
                    </Grid>
                    <Grid item xs={12}>
                        <CollapsibleSection
                            defaultExpanded={printValues.filter((value) => value).length > 0}
                            title={getTranslatedText('ACI_HIGHWAY_CARGO', 'printA8A')}
                        >
                            <Grid container spacing={1}>
                                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.portOfExit}>
                                    <NaUSPortAutocompleteField
                                        name='portOfExit'
                                        label={getTranslatedText('ACI_HIGHWAY_CARGO', 'portOfExit')}
                                        disabled={disabled}
                                        size='small'
                                        key={portOfExit}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.acquittalNo}>
                                    <CngTextField
                                        name='acquittalNo'
                                        label={getTranslatedText('ACI_HIGHWAY_CARGO', 'acquittalNo')}
                                        disabled={disabled}
                                        inputProps={{ maxLength: 25 }}
                                        onChange={(e) => {
                                            setValue('acquittalNo', e.target.value.toUpperCase())
                                        }}
                                        size='small'
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.printDate}>
                                    <CngDateField
                                        name='printDate'
                                        label={getTranslatedText('ACI_HIGHWAY_CARGO', 'printDate')}
                                        disabled={disabled}
                                        size='small'
                                    />
                                </CngGridItem>
                            </Grid>
                        </CollapsibleSection>
                    </Grid>
                </>
            )}
            <TemplateSelectDialogWithOptionACIHWYCargo
                cargoTemplateDialog={cargoTemplateDialog}
                setCargoTemplateDialog={setCargoTemplateDialog}
                showNotification={showNotification}
                getTranslatedText={getTranslatedText}
                setValue={setValue}
                reset={reset}
                trigger={trigger}
                setOnChangeCarrierCode={setOnChangeCarrierCode}
                setRefreshCarrierCodeAndCCN={setRefreshCarrierCodeAndCCN}
            />
        </Grid>

    )
}

export default CargoDetailsSection